import { useState, useEffect } from "react";
import NavbarItem from "./NavbarItem";
import NavbarItemExpandable from "./NavbarItemExpandable";
import MainLogo from "./MainLogo";
import { NavbarItemType } from "../../helpers/types";
import Search from "../search/Search";
import LanguageBtn from "./LocalSwitcher";

interface NavbarProps {
  lang?: string;
  navbarData: any;
  contentData: any;
}

export const Navbar: React.FC<NavbarProps> = ({
  lang,
  navbarData,
  contentData,
}) => {
  const [isSearchVisible, setSearchVisible] = useState<boolean>(false);
  const [isFixed, setIsFixed] = useState<boolean>(false);

  // Add scroll event listener to track scrolling past the hero section
  useEffect(() => {
    const handleScroll = () => {
      const heroHeight = document.querySelector(".hero")?.clientHeight || 0; // Get hero section height
      if (window.scrollY > heroHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderItems = (items: NavbarItemType[], isMainItem = true) => {
    return items.map((item) => {
      const name = lang === "ka" ? item.name_ka : item.name;

      if (item.children?.length) {
        return (
          <NavbarItemExpandable
            key={name!}
            parentName={name!}
            isMainItem={isMainItem}
          >
            {renderItems(item.children, false)}
          </NavbarItemExpandable>
        );
      } else {
        return (
          <NavbarItem
            key={name!}
            name={name!}
            href={`${item.href}`}
            isMainItem={isMainItem}
          />
        );
      }
    });
  };
  // bg-[#3BB085]
  return (
    <div
      className={`navbar  bg-[#fff] sm:w-[100%] w-[100dvw]  px-4 rounded-b-lg z-50  ${
        isFixed ? "fixed top-0" : ""
      } transition-all duration-300`}
    >
      {!isSearchVisible && (
        <div>
          <div className="dropdown">
            {/* for mobile */}
            <div tabIndex={0} role="button" className="btn btn-ghost xl:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>

            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-40 p-2 shadow bg-base-100 rounded-box min-w-52 w-fit"
            >
              {renderItems(navbarData, false)}
            </ul>
          </div>
          <a href={`/`}>
            <MainLogo color={"#3bb085"} width={"100px"} />
          </a>
        </div>
      )}
      {!isSearchVisible && (
        <>
          <div className="navbar-center hidden xl:flex">
            <ul className="menu menu-horizontal px-1 ">
              {renderItems(navbarData)}
            </ul>
          </div>
          <div className="navbar-end hidden sm:flex ">
            <Search lang={lang} contentData={contentData} />
          </div>
          <div className="navbar-end sm:hidden flex justify-end  items-center gap-[2dvw]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-7 h-7 opacity-70 cursor-pointer"
              onClick={() => setSearchVisible(!isSearchVisible)} // Toggle search visibility
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
            <LanguageBtn></LanguageBtn>
          </div>
        </>
      )}

      {isSearchVisible && (
        <>
          {" "}
          <div className=" flex sm:hidden justify-center items-center w-full ">
            <Search lang={lang} contentData={contentData} />
          </div>
          <div
            className="sm:hidden flex absolute right-5 text-[35px] cursor-pointer"
            onClick={() => setSearchVisible(!isSearchVisible)}
          >
            &times;
          </div>
        </>
      )}
    </div>
  );
};

import { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClick } from "../../hooks/use-outside-click";
import { useLanguages } from "../../helpers/contLang";

interface Project {
  id: number;
  title_ge: string;
  title_en: string;
  desc_ge: string;
  desc_en: string;
  full_ge: string;
  full_en: string;
  fname: string;
  prDate: string;
}

interface ExpandableCardsProps {
  data: Project[];
}

export function ExpandableCards({ data }: ExpandableCardsProps) {
  const [active, setActive] = useState<Project | boolean | null>(null);
  const id = useId();
  const ref = useRef<HTMLDivElement>(null);
  const { lang } = useLanguages();

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setActive(false);
      }
    }

    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [active]);

  useOutsideClick(ref, () => setActive(null));

  return (
    <>
      <AnimatePresence>
        {active && typeof active === "object" && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 h-full w-full z-10"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {active && typeof active === "object" ? (
          <div className="fixed inset-0 grid place-items-center z-[100] mt-2">
            <motion.button
              key={`button-${active.title_en}-${id}`}
              layout
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.05,
                },
              }}
              className="flex absolute top-4 right-4 lg:top-2 lg:right-2 z-[102] items-center justify-center bg-white rounded-full h-8 w-8 md:h-6 md:w-6 transition duration-300 ease-in-out transform hover:scale-110 hover:bg-opacity-70"
              onClick={() => setActive(null)}
            >
              <CloseIcon />
            </motion.button>
            <motion.div
              layoutId={`card-${active.title_en}-${id}`}
              ref={ref}
              className="w-full max-w-[500px] overflow-scroll h-full md:h-fit md:max-h-[90%] flex flex-col bg-white dark:bg-slate-200 sm:rounded-3xl z-[101] relative"
            >
              <motion.div layoutId={`image-${active.title_en}-${id}`}>
                <img
                  src={`https://montage.ge/img/${active.fname}`}
                  alt={lang === "ka" ? active.title_ge : active.title_en}
                  className="w-full h-80 lg:h-80 sm:rounded-tr-lg sm:rounded-tl-lg object-cover object-top"
                  loading="lazy"
                />
              </motion.div>

              <div>
                <div className="flex justify-between items-start p-4">
                  <div className="">
                    <motion.h3
                      layoutId={`title-${active.title_en}-${id}`}
                      className="font-medium text-neutral-700  text-base"
                    >
                      {lang === "ka" ? active.title_ge : active.title_en}
                    </motion.h3>
                    <motion.p
                      layoutId={`drawer-description-${active.desc_en}-${id}`}
                      className="text-base mt-2"
                    >
                      {lang === "ka"
                        ? "მდებარეობა: " + active.desc_ge
                        : "Location: " + active.desc_en}
                    </motion.p>
                  </div>
                </div>
                <div className="pt-4 relative px-4">
                  <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="text-neutral-600 text-xs md:text-sm lg:text-base h-40 md:h-fit pb-10 flex flex-col items-start gap-4 overflow-auto   [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-overflow-scrolling:touch]"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lang === "ka" ? active.full_ge : active.full_en,
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>
      <ul className="mx-auto w-full grid grid-cols-1 md:grid-cols-3 items-start gap-4 ">
        {data.map((card) => (
          <motion.div
            layoutId={`card-${card.title_en}-${id}`}
            key={card.id}
            onClick={() => setActive(card)}
            className="p-4 flex flex-col hover:bg-neutral-50 dark:hover:bg-slate-200 hover:bg-opacity-70 transition duration-300 ease-in-out transform hover:scale-105 rounded-xl cursor-pointer"
          >
            <div className="flex gap-4 flex-col w-full">
              <motion.div layoutId={`image-${card.title_en}-${id}`}>
                <img
                  src={`https://montage.ge/img/${card.fname}`}
                  alt={lang === "ka" ? card.title_ge : card.title_en}
                  className="h-60 rounded-lg object-cover object-top"
                  loading="lazy"
                />
              </motion.div>
              <div className="flex justify-center items-start flex-col">
                <motion.h3
                  layoutId={`title-${card.title_en}-${id}`}
                  className="font-medium text-neutral-800  md:text-left text-base"
                >
                  {lang === "ka" ? card.title_ge : card.title_en}
                </motion.h3>
                <motion.p
                  layoutId={`description-${card.desc_en}-${id}`}
                  className="text-neutral-600  md:text-left text-base"
                >
                  {lang === "ka" ? card.desc_ge : card.desc_en}
                </motion.p>
                <motion.p
                  layoutId={`description-${card.desc_en}-${id}`}
                  className="text-neutral-600  md:text-left text-base"
                >
                  {card.prDate}
                </motion.p>
              </div>
            </div>
          </motion.div>
        ))}
      </ul>
    </>
  );
}

export const CloseIcon = () => {
  return (
    <motion.svg
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.05,
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4 text-black"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 6l-12 12" />
      <path d="M6 6l12 12" />
    </motion.svg>
  );
};

import React, { ReactNode, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

interface NavbarItemExpandableType {
  parentName: string;
  children: ReactNode;
  isMainItem: boolean;
}

const NavbarItemExpandable: React.FC<NavbarItemExpandableType> = ({
  parentName,
  children,
  isMainItem,
}) => {
  const detailsRef = useRef<HTMLDetailsElement>(null);
  // const navigate = useNavigate();

  useEffect(() => {
    const currentDetails = detailsRef.current;

    const handleClickOutside = (event: MouseEvent) => {
      if (currentDetails && !currentDetails.contains(event.target as Node)) {
        currentDetails.removeAttribute("open");
      }
    };

    const handleClickInside = (event: MouseEvent) => {
      if (currentDetails) {
        const targetNode = event.target as Node;
        const isNavbarItem =
          currentDetails.querySelectorAll("a").length > 0 &&
          Array.from(currentDetails.querySelectorAll("a")).some((anchor) =>
            anchor.contains(targetNode)
          );
        if (isNavbarItem) {
          currentDetails.removeAttribute("open");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    if (currentDetails) {
      currentDetails.addEventListener("click", handleClickInside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (currentDetails) {
        currentDetails.removeEventListener("click", handleClickInside);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <li className="nav_font_ka text-[14px]">
      <details ref={detailsRef}>
        <summary className={"text-gray-500 nav_font_ka "}>{parentName}</summary>
        <ul className="p-2 min-w-[300px]">{children}</ul>
      </details>
    </li>
  );
};

export default NavbarItemExpandable;

import React from "react";
import { Link } from "react-router-dom";
// import styles from "./ProductCategories.module.css";

interface Category {
  title: string;
  title_ka: string;
  imgHref: string;
  href: string;
}

interface AboutUSProps {
  contentData?: any;
  lang?: string;
}

const ProductCategories: React.FC<AboutUSProps> = ({ contentData, lang }) => {
  const categories: Category[] = [
    {
      title: "Medium Voltage Switchgear",
      title_ka: "მ/ძ უჯრედები",
      imgHref: `https://montage.ge/img/1_1Medium Voltage Switchgear.png`,
      href: "/products/medium-voltage-switchgear/1200",
    },
    {
      title: "Transformators",
      title_ka: "ტრანსფორმატორები",
      imgHref: `https://montage.ge/img/1.2Transformators.png`,
      href: "/products/transformators/1300",
    },
    {
      title: "Switchboards and Circuit Breakers",
      title_ka: "ელ.გამანაწილებელი ფარები და ავტ.ამომრთველები",
      imgHref: `https://montage.ge/img/2.1Circuit Breakers.png`,
      href: "/products/switchboards-and-circuit-breakers/2500",
    },
    {
      title: "Generators",
      title_ka: "გენერატორები",
      imgHref: `https://montage.ge/img/2.2Generators.png`,
      href: "/products/generators/1400",
    },
    {
      title: "UPS",
      title_ka: "უპს",
      imgHref: `https://montage.ge/img/2.3UPS.png`,
      href: "/products/ups/1500",
    },
    {
      title: "Busbars",
      title_ka: "ძალოვანი სალტეები",
      imgHref: `https://montage.ge/img/3.1Busbars.png`,
      href: "/products/busbars/2200",
    },
    {
      title: "Cable Trays, Ladders and Conduits",
      title_ka: "კაბელსატარები",
      imgHref: `https://montage.ge/img/3.2Cable_ trays Ladders and Conduits.png`,
      href: "/products/cable-trays-ladders-and-conduits/2100",
    },
    {
      title: "Supporting Material",
      title_ka: "დამხმარე მასალა",
      imgHref: `https://montage.ge/img/3.3Lightning Fixtures.png`,
      href: "/products/lightning-fixtures/2800",
    },
    {
      title: "Cables",
      title_ka: "სადენები",
      imgHref: `https://montage.ge/img/3.4Cables.png`,
      href: "/products/cables/2300",
    },
    {
      title: "IT Power Systems",
      title_ka: "IT სისტემა",
      imgHref: `https://montage.ge/img/4.1IT Power Systems.png`,
      href: "/products/it-power-systems/3100",
    },
    {
      title: "Fire Alarm Systems",
      title_ka: "სახანძრო მაუწყებლობის სისტემა",
      imgHref: `https://montage.ge/img/4.2Fire Alarm Systems.png`,
      href: "/products/fire-alarm-systems/3200",
    },
    {
      title: "Public Address Systems",
      title_ka: "გახმოვანების სისტემა",
      imgHref: `https://montage.ge/img/4.3Public Address Systems.png`,
      href: "/products/public-address-systems/3300",
    },
    {
      title: "CCTV Systems",
      title_ka: "ვიდეომეთვალყურეობის სისტემა",
      imgHref: `https://montage.ge/img/4.4CCTV Systems.png`,
      href: "/products/cctv-systems/3400",
    },
    {
      title: "Access Control Systems",
      title_ka: "დაშვების სისტემები",
      imgHref: `https://montage.ge/img/4.5Access Control Systems.png`,
      href: "/products/access-control-systems/3500",
    },
    {
      title: "Security Systems",
      title_ka: "დაცვის სისტემები",
      imgHref: `https://montage.ge/img/4.6Security Systems.png`,
      href: "/products/security-systems/3600",
    },
    {
      title: "Emergency Call Systems",
      title_ka: "შშმპ სისტემა",
      imgHref: `https://montage.ge/img/4.7Emergency Call Systems.png`,
      href: "/products/emergency-call-systems/3700",
    },
    {
      title: "Building Management Systems",
      title_ka: "BMS სისტემა",
      imgHref: `https://montage.ge/img/4.8Building Management Systems.png`,
      href: "/products/building-management-systems/3800",
    },
    {
      title: "Sockets and Switches",
      title_ka: "როზეტები და ჩამრთველები",
      imgHref: `https://montage.ge/img/5.1Sockets and Switches.png`,
      href: "/products/sockets-and-switches/2600",
    },
    {
      title: "Lightning Fixtures",
      title_ka: "სანათები",
      imgHref: `https://montage.ge/img/5.2Lightning Fixtures.png`,
      href: "/products/lightning-fixtures/2700",
    },
  ];

  return (
    <div
      className="flex justify-between flex-col gap-4 z-40 mt-0 sm:mt-36  mb-4"
      id="product-section"
    >
      <div
        className="mt-7 grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-5 pr-1"
        id="product-section"
      >
        {categories.map((category, index) => (
          <Link to={category.href} key={index}>
            <div className="h-[88px] rounded-lg flex items-center justify-between overflow-hidden relative border border-gray-200 shadow-[10px_5px_5px_rgba(107,114,128,0.5)] p-2 cursor-pointer hover:shadow-[12px_6px_6px_rgba(107,114,128,0.5)] hover:scale-105 transition-transform duration-200 ease-in-out text-sm md:text-base leading-tight md:leading-normal">
              <div className="flex-1 font-bold text-gray-800 main_font_ka text-[0.8rem] md:text-[0.9rem] leading-tight md:leading-normal break-words px-2 w-8">
                {lang === "en" ? category.title : category.title_ka}
              </div>

              <img
                src={category.imgHref}
                alt={lang === "en" ? category.title : category.title_ka}
                className="w-1/3 h-full object-cover"
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductCategories;

import React from "react";

interface MainLogoProps {
  color: string;
  width: string;
}

const MainLogo: React.FC<MainLogoProps> = ({ color, width }) => {
  return (
    <div style={{ marginTop: "-10px", marginRight:"110px" }}>
      <svg
        width={width}
        height="70.365px"
        viewBox="-93.977 -14.31 216.56 70.365"
        fill={color}
      >
        <g>
          <path
            d="M-22.453,32.769c0.092-0.707,0.05-1.527-0.221-2.173c-4.504-10.702-9.068-21.378-13.572-32.08
                            c-0.459-1.091-1.036-1.498-2.253-1.042c0,1.872,0,3.784,0,5.695c0,1.928,0,3.857,0,5.783c-0.235-0.121-0.568-0.349-0.936-0.47
                            c-1.354-0.445-1.688-1.353-1.628-2.736c0.12-2.749,0.035-5.508,0.035-8.309c-1.251-0.372-1.854,0.008-2.286,1.124
                            c-1.066,2.751-2.268,5.45-3.425,8.166c-3.408,8.002-6.839,15.995-10.206,24.015c-0.375,0.894-0.954,1.098-1.652,0.819
                            c-0.377-0.151-0.627-0.76-0.82-1.212C-63.938,19.744-68.462,9.138-72.93-1.492c-0.467-1.112-1.051-1.469-2.264-1.038
                            c0,0.348,0,0.739,0,1.129c0,2.68,0.021,5.36-0.021,8.039c-0.006,0.414-0.183,0.977-0.483,1.203
                            c-0.652,0.492-1.434,0.811-2.147,1.192c0-3.938,0-7.789,0-11.615c-1.159-0.425-1.689,0.077-2.14,1.15
                            C-84.507,9.306-89.082,20.02-93.63,30.747c-0.166,0.391-0.349,0.855-0.289,1.25c0.363,2.399,0.794,4.788,1.22,7.282
                            c2.095,0,4.129,0.043,6.159-0.024c0.685-0.023,1.424-0.205,2.032-0.52c3.049-1.582,6.063-3.234,9.09-4.858
                            c1.926-1.033,3.855-2.061,5.862-3.134c0.128,0.369,0.235,0.619,0.301,0.879c0.545,2.167,1.144,4.324,1.594,6.51
                            c0.198,0.963,0.622,1.174,1.531,1.167c5.159-0.041,10.318-0.01,15.478-0.024c2.156-0.006,1.693,0.274,2.221-1.765
                            c0.576-2.225,1.119-4.459,1.698-6.775c0.473,0.237,0.795,0.387,1.108,0.555c4.611,2.474,9.221,4.953,13.834,7.424
                            c0.384,0.206,0.79,0.514,1.192,0.522c2.349,0.05,4.699,0.024,7.098,0.024C-23.131,37.022-22.732,34.903-22.453,32.769z
                             M-74.849,8.98c0.397,1.582,0.764,3.047,1.196,4.768c-1.752-0.936-3.254-1.739-5.033-2.689C-77.314,10.316-76.17,9.696-74.849,8.98
                            z M-78.927,12.485c1.965,1.051,3.777,2.02,5.848,3.127c-2.621,1.404-4.994,2.675-7.626,4.085
                            C-80.077,17.15-79.521,14.892-78.927,12.485z M-72.843,17.036c0.855,3.473,1.675,6.807,2.562,10.411
                            c-3.766-2.022-7.261-3.899-10.975-5.894C-78.366,20.002-75.682,18.561-72.843,17.036z M-85.098,37.525
                            c1.226-4.984,2.382-9.684,3.581-14.555c3.949,2.118,7.755,4.16,11.773,6.315C-74.912,32.058-79.874,34.721-85.098,37.525z
                             M-41.385,8.976c1.274,0.684,2.444,1.311,3.867,2.075c-1.784,0.953-3.28,1.752-5.027,2.685
                            C-42.128,12.027-41.775,10.579-41.385,8.976z M-37.296,12.479c0.591,2.387,1.154,4.663,1.788,7.222
                            c-2.664-1.429-5.039-2.703-7.616-4.086C-41.114,14.534-39.301,13.559-37.296,12.479z M-43.372,17.037
                            c2.831,1.519,5.514,2.958,8.417,4.515c-3.706,1.99-7.193,3.863-10.978,5.895C-45.043,23.828-44.224,20.499-43.372,17.037z
                             M-46.466,29.286c3.983-2.14,7.765-4.172,11.758-6.318c1.196,4.838,2.361,9.552,3.6,14.561
                            C-36.341,34.721-41.304,32.057-46.466,29.286z"
          ></path>
          <path
            d="M97.059,16.196c-1.899,0-3.665,0-5.488,0c0,0.98,0,1.856,0,2.732c-0.127,0.091-0.255,0.183-0.382,0.275
                            c-0.103-0.212-0.163-0.464-0.316-0.631c-3.657-3.979-9.45-3.964-13.11,0.026c-1.729,1.885-2.414,4.201-2.655,6.668
                            c-0.376,3.854,0.095,7.526,2.74,10.589c3.33,3.855,9.034,3.911,12.482,0.171c0.23-0.249,0.466-0.493,0.812-0.86
                            c0,1.594,0.017,3.008-0.005,4.421c-0.023,1.484-0.396,2.858-1.953,3.39c-1.925,0.657-3.911,0.908-5.784-0.197
                            c-0.333-0.197-0.471-0.714-0.719-1.068c-0.147-0.209-0.314-0.535-0.507-0.561c-2.114-0.292-4.234-0.536-6.363-0.794
                            c-0.157,3.323,1.486,5.789,4.505,6.787c3.222,1.065,6.543,1.055,9.841,0.484c4.028-0.697,6.148-3.008,6.643-7.074
                            c0.159-1.305,0.241-2.628,0.246-3.943c0.025-6.398,0.012-12.795,0.012-19.193C97.059,17.028,97.059,16.638,97.059,16.196z
                             M90.794,30.368c-0.776,2.414-2.985,3.876-5.302,3.618c-2.283-0.254-4.016-2.278-4.304-5.054c-0.066-0.635-0.072-1.276-0.105-1.914
                            c-0.045-0.01-0.09-0.02-0.136-0.029c0.226-1.208,0.331-2.455,0.703-3.616c0.655-2.041,2.405-3.265,4.398-3.286
                            c1.985-0.021,3.793,1.169,4.554,3.117C91.521,25.556,91.564,27.974,90.794,30.368z"
          ></path>
          <path
            d="M-5.884,39.302C0.894,39.304,5.77,34.314,5.762,27.384C5.754,20.536,0.89,15.619-5.895,15.599
                            c-6.769-0.02-11.675,4.844-11.703,11.601C-17.628,34.605-13.084,39.301-5.884,39.302z M-10.996,24.18
                            c0.822-2.395,2.755-3.753,5.149-3.735c2.331,0.018,4.234,1.38,5.007,3.744c0.342,1.047,0.443,2.173,0.657,3.285
                            c-0.219,1.122-0.33,2.244-0.667,3.295c-0.747,2.329-2.706,3.74-5.005,3.753c-2.314,0.013-4.301-1.359-5.106-3.644
                            C-11.742,28.665-11.758,26.402-10.996,24.18z"
          ></path>
          <path
            d="M65.047,36.696c0.112,0.349,0.24,0.933,0.492,1.457c0.125,0.26,0.472,0.576,0.727,0.584
                            c1.706,0.052,3.414,0.027,5.254,0.027c-1.594-3.475-1.302-7.038-1.251-10.585c0.031-2.155,0.027-4.316-0.107-6.465
                            c-0.197-3.143-1.705-4.903-4.764-5.632c-2.594-0.619-5.231-0.646-7.855-0.199c-3.469,0.591-5.54,2.706-6.487,6.162
                            c1.628,0.294,3.187,0.597,4.756,0.836c0.223,0.034,0.591-0.172,0.724-0.377c1.523-2.347,3.574-3.042,6.249-2.078
                            c1.194,0.431,1.763,1.464,1.725,2.971c-0.031,1.224-1.094,0.97-1.746,1.233c-0.695,0.28-1.47,0.357-2.205,0.541
                            c-2.048,0.514-4.146,0.887-6.126,1.593c-2.746,0.979-4.13,3.285-4.02,6.152c0.109,2.836,1.699,5.203,4.414,5.892
                            c1.612,0.41,3.453,0.565,5.053,0.209C61.669,38.619,63.301,37.514,65.047,36.696z M58.236,29.533
                            c1.99-0.668,4.066-1.081,6.109-1.609c0.684,3.674-0.256,5.916-2.843,6.907c-1.425,0.546-2.825,0.596-4.066-0.442
                            C55.59,32.845,55.965,30.296,58.236,29.533z"
          ></path>
          <path
            d="M111.009,34.727c-2.183-0.639-3.622-2.922-3.403-5.537c4.886,0,9.785,0,14.977,0c-0.151-1.719-0.189-3.235-0.433-4.718
                            c-0.889-5.407-4.452-8.577-9.861-8.865c-4.838-0.258-8.936,2.678-10.298,7.463c-0.837,2.941-0.872,5.936-0.04,8.877
                            c0.991,3.504,3.143,5.983,6.772,6.938c2.279,0.6,4.602,0.645,6.88,0.059c3.296-0.848,5.398-2.999,6.547-6.269
                            c-2.019-0.333-3.897-0.643-5.741-0.946C114.792,34.662,113.391,35.424,111.009,34.727z M112.041,20.087
                            c2.696-0.051,4.622,2.231,4.492,5.421c-2.923,0-5.851,0-8.866,0C107.409,22.53,109.378,20.138,112.041,20.087z"
          ></path>
          <path
            d="M31.2,38.686c0-1.151,0.011-2.222-0.002-3.293c-0.056-4.553-0.035-9.109-0.21-13.658c-0.105-2.721-1.567-4.67-4.2-5.547
                            c-3.644-1.214-6.931-0.584-9.728,2.133c-0.298,0.29-0.492,0.688-0.734,1.036c-0.127-0.075-0.253-0.15-0.38-0.225
                            c0-0.967,0-1.934,0-2.951c-1.882,0-3.615,0-5.393,0c0,7.542,0,14.994,0,22.517c1.922,0,3.782,0,5.831,0c0-0.522,0-0.954,0-1.386
                            c0.003-3.357-0.058-6.715,0.038-10.07c0.042-1.457,0.214-2.969,0.669-4.343c0.636-1.921,2.96-3.085,4.949-2.774
                            c1.863,0.291,2.987,1.48,3.074,3.812c0.163,4.389,0.135,8.785,0.185,13.179c0.006,0.508,0.001,1.016,0.001,1.571
                            C27.321,38.686,29.211,38.686,31.2,38.686z"
          ></path>
          <path
            d="M46.751,34.191c-2.441,0.62-3.196,0.07-3.213-2.408c-0.023-3.279,0.012-6.558-0.034-9.836
                            c-0.012-0.849,0.163-1.262,1.114-1.188c0.973,0.076,1.957,0.017,2.903,0.017c0-1.666,0-3.122,0-4.717c-1.356,0-2.615,0-4.041,0
                            c0-2.691,0-5.245,0-7.957c-1.883,1.114-3.585,2.078-5.23,3.131c-0.319,0.204-0.561,0.747-0.587,1.149
                            c-0.076,1.214-0.027,2.435-0.027,3.658c-1.044,0.046-1.861,0.082-2.655,0.117c0,1.614,0,3.074,0,4.61c0.876,0,1.667,0,2.655,0
                            c0,0.597-0.004,1.028,0.001,1.459c0.046,4.078,0.057,8.156,0.154,12.232c0.05,2.08,0.903,3.73,3.022,4.425
                            c2.252,0.738,4.496,0.521,6.704-0.217c0.229-0.077,0.537-0.429,0.524-0.636c-0.083-1.337-0.245-2.668-0.388-4.064
                            C47.208,34.076,46.979,34.133,46.751,34.191z"
          ></path>
          <path
            d="M-77.681-10.227c0.861,0,1.605,0,2.515,0c0,2.034,0,4.005,0,5.979c0.634,0,1.101,0,1.695,0c0-2.369,0-4.681,0-6.992
                            c0-1.744-1.201-2.946-2.942-2.946c-1.833,0-2.948,1.101-2.95,2.915c-0.001,1.359,0,2.719,0,4.078c0,0.983,0,1.966,0,2.919
                            c0.651,0,1.109,0,1.683,0C-77.681-6.296-77.681-8.235-77.681-10.227z"
          ></path>
          <path
            d="M-41.033-4.253c0-2.004,0-3.945,0-5.96c0.886,0,1.66,0,2.545,0c0,2.022,0,3.963,0,5.963c0.567,0,1.023,0,1.633,0
                            c0-2.312-0.106-4.581,0.037-6.834c0.095-1.497-0.708-2.33-1.742-2.998c-0.848-0.548-2.627-0.051-3.403,0.901
                            c-0.387,0.475-0.69,1.162-0.71,1.764c-0.08,2.375-0.033,4.755-0.033,7.164C-42.061-4.253-41.603-4.253-41.033-4.253z"
          ></path>
          <path
            d="M-29.567-9.684c0.716,0.031,1.484,0.244,2.114,0.585c1.459,0.789,2.849,1.706,4.303,2.593
                            c0.324-0.543,0.585-0.979,0.885-1.481c-0.884-0.55-1.779-1.003-2.554-1.609c-2.016-1.577-4.242-2.218-6.796-1.933
                            c-1.206,0.135-2.439,0.023-3.688,0.023c0,0.676,0,1.145,0,1.795C-33.306-9.711-31.433-9.764-29.567-9.684z"
          ></path>
          <path
            d="M-53.021-8.575c1.256-0.938,2.596-1.248,4.134-1.156c1.533,0.092,3.077,0.02,4.618,0.02c0-0.678,0-1.173,0-1.781
                            c-2.429,0-4.775-0.003-7.121,0.007c-0.15,0.001-0.313,0.075-0.446,0.156C-53.634-10.233-55.428-9.13-57.271-8
                            c0.305,0.514,0.564,0.95,0.888,1.497C-55.18-7.234-54.035-7.817-53.021-8.575z"
          ></path>
          <path
            d="M-88.871-9.024c0.703-0.371,1.543-0.616,2.335-0.656c1.86-0.094,3.729-0.03,5.591-0.03c0-0.669,0-1.168,0-1.795
                            c-1.74,0-3.411-0.008-5.081,0.003c-0.757,0.005-1.642-0.199-2.246,0.117c-1.957,1.024-3.811,2.245-5.705,3.391
                            c0.34,0.548,0.592,0.954,0.925,1.489C-91.602-7.391-90.276-8.283-88.871-9.024z"
          ></path>
          <path
            d="M-67.209-9.729c1.45-0.079,2.716,0.214,3.91,1.085c1.078,0.786,2.272,1.413,3.466,2.141
                            c0.319-0.542,0.575-0.978,0.879-1.494c-1.466-0.906-2.876-1.789-4.299-2.651c-0.507-0.307-1.037-0.786-1.571-0.806
                            c-2.338-0.089-4.681-0.038-7.083-0.038c0,0.644,0,1.143,0,1.781C-70.297-9.711-68.749-9.646-67.209-9.729z"
          ></path>
          <path
            d="M-26.067-1.637c0.39-0.641,0.657-1.079,0.891-1.464c-2.923-3.005-6.407-3.312-10.195-2.689
                            c0.033,0.611,0.057,1.071,0.09,1.69C-31.839-4.474-28.604-4.35-26.067-1.637z"
          ></path>
          <path
            d="M-54.4-3.159c0.275,0.442,0.556,0.893,0.95,1.526c2.573-2.707,5.761-2.857,9.159-2.458c0-0.674,0-1.199,0-1.719
                            C-48.019-6.625-51.45-5.734-54.4-3.159z"
          ></path>
          <path
            d="M-91.044-3.116c0.113,0.192,0.248,0.432,0.395,0.664c0.139,0.218,0.293,0.427,0.57,0.829
                            c2.525-2.911,5.878-2.713,9.163-2.541c0-0.703,0-1.175,0-1.639C-84.719-6.457-88.198-6.026-91.044-3.116z"
          ></path>
          <path
            d="M-62.714-1.641c0.384-0.671,0.636-1.11,0.917-1.6c-2.259-1.493-4.316-3.277-7.278-2.747
                            c-0.464,0.083-0.955,0.011-1.433,0.012c-1.582,0.003-1.582,0.004-1.331,1.878C-68.458-4.538-65.279-4.257-62.714-1.641z"
          ></path>
          <path
            d="M-40.173,47.808h4.624v3.104c-0.675,0.154-1.991,0.277-3.916,0.277c-5.299,0-8.775-1.56-8.775-4.2
                            c0-2.609,3.611-4.185,9.147-4.185c2.295,0,3.814,0.201,5.029,0.448l0.709-1.097c-0.979-0.217-3.038-0.479-5.67-0.479
                            c-7.628,0-12.286,2.27-12.32,5.373c0,1.622,1.215,3.012,3.173,3.876c2.228,0.974,5.063,1.375,8.506,1.375
                            c3.071,0,5.67-0.355,6.987-0.571v-5.004h-7.493V47.808z"
          ></path>
          <path
            d="M-18.944,44.55c-4.759,0-7.662,1.775-7.662,4.03c0,2.254,3.004,3.768,8.033,3.768c2.599,0,4.388-0.247,5.434-0.463
                            l-0.506-0.973c-1.114,0.216-2.396,0.386-4.523,0.386c-2.97,0-5.536-0.757-5.603-2.595h11.476c0.034-0.139,0.101-0.354,0.101-0.633
                            C-12.194,46.696-13.611,44.55-18.944,44.55z M-23.737,47.73c0.236-0.941,1.553-2.208,4.557-2.208c3.342,0,4.152,1.344,4.118,2.208
                            H-23.737z"
          ></path>
          <path
            d="M1.104,44.55c-4.523,0-8.101,1.467-8.101,3.969c0,2.362,3.409,3.845,7.83,3.845c3.949,0,8.135-1.204,8.135-3.969
                            C8.968,46.109,5.795,44.55,1.104,44.55z M0.969,51.344c-2.903,0-4.962-1.235-4.962-2.872c0-1.421,1.519-2.903,5.029-2.903
                            c3.51,0,4.894,1.606,4.894,2.873C5.93,50.124,3.804,51.344,0.969,51.344z"
          ></path>
          <path
            d="M18.114,46.187h-0.135l-0.102-1.467h-2.599c0.101,0.694,0.135,1.451,0.135,2.331v5.143h2.937v-3.984
                            c0-0.231,0.067-0.447,0.135-0.633c0.405-1.02,1.891-1.745,3.983-1.745c0.404,0,0.708,0.016,1.013,0.047v-1.282
                            c-0.271-0.03-0.507-0.046-0.845-0.046C20.646,44.55,18.856,45.183,18.114,46.187z"
          ></path>
          <path
            d="M40.222,45.847h-0.067c-0.709-0.617-2.262-1.297-5.097-1.297c-3.746,0-7.358,1.436-7.358,3.969
                            c0,2.069,2.903,3.644,6.953,3.644c2.531,0,4.287-0.556,5.198-1.25h0.067v0.833c0,1.885-2.228,2.61-5.266,2.61
                            c-2.024,0-3.713-0.278-4.793-0.587l-0.742,1.034c1.316,0.401,3.477,0.618,5.435,0.618c2.059,0,4.354-0.217,5.94-0.896
                            c1.586-0.648,2.328-1.699,2.328-3.413v-4.339c0-0.911,0.067-1.514,0.136-2.054h-2.6L40.222,45.847z M39.884,49.013
                            c0,0.231-0.067,0.494-0.236,0.726c-0.641,0.864-2.295,1.39-4.117,1.39c-3.207,0-4.827-1.22-4.827-2.687
                            c0-1.729,2.025-2.826,4.86-2.826c2.16,0,3.612,0.648,4.152,1.436c0.135,0.186,0.168,0.387,0.168,0.618V49.013z"
          ></path>
          <rect x="50.245" y="44.72" width="2.971" height="7.474"></rect>
          <path
            d="M51.73,41.771c-1.08,0-1.856,0.37-1.856,0.849c0,0.464,0.742,0.834,1.789,0.834c1.181,0,1.924-0.37,1.89-0.834
                            C53.553,42.141,52.844,41.771,51.73,41.771z"
          ></path>
          <path
            d="M72.318,47.607c0-1.498-1.215-3.058-6.21-3.058c-2.059,0-4.017,0.263-5.367,0.664l0.676,0.896
                            c1.147-0.34,2.733-0.556,4.252-0.556c3.342,0,3.713,1.111,3.713,1.729v0.154c-6.312-0.016-9.821,0.973-9.821,2.779
                            c0,1.081,1.688,2.146,4.995,2.146c2.329,0,4.084-0.525,4.995-1.112h0.102l0.236,0.942h2.7c-0.203-0.51-0.271-1.143-0.271-1.791
                            V47.607z M69.45,49.677c0,0.139-0.068,0.293-0.169,0.432c-0.473,0.634-1.823,1.251-3.949,1.251c-1.519,0-2.802-0.417-2.802-1.297
                            c0-1.451,3.68-1.714,6.92-1.684V49.677z"
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default MainLogo;

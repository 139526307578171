import { useState } from "react";

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options: Option[];
  selectedValue: string;
  onChange: (value: string) => void;
  placeholder: string;
  lang?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  selectedValue,
  onChange,
  placeholder,
  lang = "en",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // Filter out duplicate options
  const uniqueOptions = Array.from(
    new Set(options.map((option) => option.value))
  ).map((value) => options.find((option) => option.value === value)!);

  const handleOptionClick = (value: string) => {
    if (value !== selectedValue) {
      onChange(value);
    }
    setIsOpen(false);
  };

  const handleClearSelection = () => {
    onChange("");
    setIsOpen(false);
  };

  return (
    <div className="relative w-full sm:w-[45%] md:w-[30%] lg:w-[150px]">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="block w-full py-3 px-4 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-[#3BB085] transition"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {selectedValue || placeholder}
      </button>

      {isOpen && (
        <div className="absolute top-full mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 z-10">
          {/* Scrollable options */}
          <div className="max-h-48 overflow-auto">
            {uniqueOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option.value)}
                className={`px-4 py-2 text-sm cursor-pointer hover:bg-[#3BB085] hover:text-white ${
                  selectedValue === option.value
                    ? "bg-[#3BB085] text-white"
                    : "text-gray-700"
                }`}
              >
                {option.label}
              </div>
            ))}
          </div>
          <div
            onClick={handleClearSelection}
            className="px-4 py-2 text-sm text-white cursor-pointer bg-[#2B2D37]  border-t border-gray-200 text-center"
          >
            {lang === "ka" ? "გასუფთავება" : "Clear selection"}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;

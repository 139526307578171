import React from "react";
// import "aos/dist/aos.css";
import Card from "./Card";
import { Returntext } from "../../helpers/helpers";

interface CardSectionProps {
  contentData?: any;
  lang?: string;
}

const CardSection: React.FC<CardSectionProps> = ({ contentData, lang }) => {
  const cardsContent = [
    Returntext(contentData, "service_1_title", lang),
    Returntext(contentData, "service_2_title", lang),
    Returntext(contentData, "service_3_title", lang),
    Returntext(contentData, "service_4_title", lang),
    Returntext(contentData, "service_5_title", lang),
    Returntext(contentData, "service_6_title", lang),
  ];

  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);

  return (
    <div className="bg-[#2B2D37] flex flex-col justify-center items-center w-full px-4 sm:px-8 lg:px-[10dvw] py-8 sm:py-12 lg:py-16 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      <div className="text-[#3BB085] uppercase font-semibold text-sm sm:text-base lg:text-lg">
        {Returntext(contentData, "our_work_our_sevices", lang)}
      </div>
      <div className="text-[#fff] uppercase font-bold h2_font_ka text-[28px] sm:text-[32px] lg:text-[42px] text-center mt-2">
        {Returntext(contentData, "our_work_our_offers", lang)}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:text-xs lg:grid-cols-3 gap-5 mt-8">
        {cardsContent.map((item, index) => (
          <div key={index} data-aos="fade-right" className="w-full">
            <Card text={item} imagePath={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSection;

// import heroIMG from "../../assets/images/hero.jpg";
import { Returntext } from "../../helpers/contLang";
import ProductCategories from "../productCategories/ProductCategories";

interface HeroType {
  contentData?: any;
  lang?: string;
}

const Hero: React.FC<HeroType> = ({ contentData, lang }) => {
  return (
    <div className="relative w-full min-h-screen overflow-hidden">
      <div
        className="absolute inset-0  bg-center bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]"
        // style={{
        //   // backgroundImage: `url(${heroIMG})`,
        //   // backgroundColor: 'grey',

        //   // filter: "blur(2px)",
        // }}
      >
        <div className="absolute inset-0 bg-white opacity-20"></div>
      </div>
      <div className="relative z-10 flex flex-col md:flex-row items-center justify-between gap-1 md:gap-10 px-6 md:px-1 lg:p-5 font-sans">
        <div className="text-center md:text-left md:w-1/6 mt-20">
          <div className="text-[20px] md:text-[25px] lg:text-[28px] text-[#3bb085] h1_font_ka mb-4">
            {Returntext(contentData, "MG", lang)}
          </div>
          <div className="text-[30px] md:text-[40px] lg:text-[55px] leading-snug md:leading-tight lg:leading-[70px] font-bold h2_font_ka max-w-full md:max-w-[500px]">
            {Returntext(contentData, "MG_slogan", lang)}
          </div>
        </div>

        <div className="w-full md:w-5/6">
          <ProductCategories contentData={contentData} lang={lang} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
